import React, { useEffect, useState } from 'react';

import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';

import { API } from 'aws-amplify';

import DogListItem from './DogListItem';
import Dog from './Dog';
import FilterMenu from '../Layout/FilterMenu';
import LoadingButton from '../Layout/LoadingButton';

const useStyles = makeStyles((theme) => ({}));

export default function DogList({ appData }) {
  const classes = useStyles();

  const [dogs, setDogs] = useState(null);
  const [selectedDog, setSelectedDog] = useState(null);

  const [dogsOrCats, setDogsOrCats] = useState("dogs");
  const [filter, setFilter] = useState({ 
    sex: [], 
    breed: [],
    age: [],
  });
  const [appliedFilter, setAppliedFilter] = useState({ 
    sex: [], 
    breed: [],
    age: [],
  });

  const [page, setPage] = useState(1);
  const [numResults, setNumResults] = useState(15);
  const [pages, setPages] = useState(1);
  const [count, setCount] = useState(1);

  const [loading, setLoading] = useState(true);

  const applyFilter = async () => {
    setAppliedFilter(filter);
    await getDogs(1, filter);
  };

  const getDogs = async (page, filter) => { 
    try {
      setLoading(true);

      const data = (filter) 
        ? { body: { filter } } 
        : { body: { filter: appliedFilter }};

      const url = `/dogs?numResults=${numResults}&page=${page}`;
      const response = await API.post('rescuepaws', url, data);

      setDogs(response.data);
      setPages(+response.pagination.pages);
      setPage(+response.pagination.page);
      setCount(+response.pagination.count);

      setLoading(false);
    } catch (err) {
      console.error("error making API request", err);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!dogs) getDogs(1, null);
  }, []);

  const onPageChange = async (event, value) => {
    if (value !== page) {
      setPage(value);
      await getDogs(value, null);
    } 
  };

  if (loading) return (
    <LoadingButton type="screen"/>
  );

  if (!dogs) return null;

  const selectDog = (dog) => {
    setSelectedDog(dog);
  };

  const unselectDog = () => {
    setSelectedDog(null);
  };

  const Dogs = () => (
    <Grid container spacing={4}>
      {dogs.map((dog) => (
        <DogListItem 
          key={dog._id} 
          dog={dog}
          selectDog={() => selectDog(dog)}
        />
      ))}
    </Grid>
  );

  const PaginationControl = ({ pages, page, onPageChange }) => (
    <Grid item>

      {/* Small screen pagination */}
      <Hidden mdUp>
        <Pagination 
          count={pages} 
          size="small"
          variant="outlined" 
          onChange={onPageChange}
          page={page}
          boundaryCount={2}
          siblingCount={0}
        />
      </Hidden>

      {/* Larger screen pagination */}
      <Hidden smDown>
        <Pagination 
          count={pages} 
          size="large"
          variant="outlined" 
          onChange={onPageChange}
          page={page}
          boundaryCount={2}
          siblingCount={1}
        />
      </Hidden>

    </Grid>
  );

  if (selectedDog) return (
    <Grid container direction="column" xs={12}>
      <Dog 
        dog={selectedDog} 
        close={unselectDog}
      />
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      <FilterMenu 
        dogsOrCats={dogsOrCats} 
        setDogsOrCats={setDogsOrCats}
        filter={filter}
        setFilter={setFilter}
        apply={applyFilter}
        appData={appData}
      />
      {dogsOrCats === "dogs" ? (
        <>
          <Grid item xs={12}>
            <Dogs/>
          </Grid>
          <Grid container item xs={12} justify="center">
            <PaginationControl
              page={page}
              pages={pages}
              onPageChange={onPageChange}
            />
          </Grid>
        </>
      ) : (
        <Grid container item xs={12} justify="center">
          <Typography 
            variant="h4" 
            component= "p"
            style={{ textAlign: "center" }}
          >
            Support for cats coming soon!
          </Typography>
        </Grid>
      )}
    </Grid> 
  );
}
