import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { API, loadingSceneName } from 'aws-amplify';

import { Container, } from '@material-ui/core';

import Menu from '../Layout/Menu';
import Routes from './Routes';
import LoadingButton from '../Layout/LoadingButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '97vh',
    overflow: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const defaultAppData = {
  dog: {
    breed: [],
    sex: [],
    age: [],
  }
};

export default function RescuePaws({ user }) {
  const classes = useStyles();

  const [notifications, setNotifications] = useState([]);
  const [appData, setAppData] = useState(defaultAppData);
  const [loading, setLoading] = useState(true);

  const getNotifications = async () => { 
    try {
      const response = await API.get('rescuepaws', `/notifications`);
      setNotifications(response);
    } catch (err) {
      console.error("error making API request", err);
      setNotifications([]);
    }
  };

  const getAppData = async () => { 
    try {
      const response = await API.get('rescuepaws', `/app/init`);
      setAppData(response);
    } catch (err) {
      console.error("error making API request", err);
      setAppData(defaultAppData);
    }
  };

  useEffect(() => {
    setLoading(true);
    getNotifications();
    getAppData();
    setLoading(false);
  }, []);

  if (loading) return (<LoadingButton type="screen" />);
  
  return (
    <div className={classes.root}>
      <Menu user={user} notifications={notifications}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Routes user={user} appData={appData} />
        </Container>
      </main>
    </div>
  );
};