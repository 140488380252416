import React from 'react';

import { Card, Grid, Select, Input, Box, FormControl, 
  Checkbox, ListItemText, Chip, MenuItem 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  filterFieldsContainer: {
    marginTop: theme.spacing(1),
  },
  filterFieldContainer: {
    padding: theme.spacing(1),
  },
  filterFieldLabel: {
    fontWeight: 600,
  },
  formControl: {
    width: "100%"    
  },
  formControlText: {
    fontWeight: 600,
    paddingLeft: theme.spacing(1)
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function FilterFieldsContainer({ filter, setFilter, appData }) {
  const classes = useStyles();
  const { breeds, sexes, ages } = appData.dog;

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updated = { ...filter };
    updated[name] = value;

    // Sex can only be male, female or no filter
    if (name === "sex" && updated[name].length > 1) {
      updated[name].shift();
    }

    setFilter(updated);
  }

  const FilterField = ({ children }) => (
    <Grid item xs={12} md={4}>
      <Card className={classes.filterFieldContainer}>
        <Grid container item>
          {children}
        </Grid>
      </Card>
    </Grid>
  );

  const FilterInput = ({ value, label, values, name }) => (
    <FormControl className={classes.formControl}>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        name={name}
        input={<Input />}
        renderValue={() => (
          <Grid container justify="flex-start" >
            <Box variant="body2" fontWeight={600} className={classes.formControlText}>{label}</Box>
          </Grid>
        )}
        displayEmpty={true}
        MenuProps={MenuProps}
      >
        {values.map((v) => (
          <MenuItem key={v} value={v}>
            <Checkbox checked={value.indexOf(v) > -1} />
            <ListItemText primary={v} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const Chips = () => (
    <Grid item container spacing={3} className={classes.chipsContainer}>
      {[
        ...filter.age.map(v => (<Chip key={v} label={v} className={classes.chip}/>)),
        ...filter.breed.map(v => (<Chip key={v} label={v} className={classes.chip}/>)),
        ...filter.sex.map(v => (<Chip key={v} label={v} className={classes.chip}/>)),
      ]}
    </Grid>      
  );

  return (
    <Grid container spacing={3} className={classes.filterFieldsContainer}>    
      <FilterField>
        <FilterInput 
          value={filter.breed}
          values={breeds}
          label="Breed"
          name="breed"
        />
      </FilterField>

      <FilterField>
        <FilterInput 
          value={filter.age}
          values={ages}
          label="Age"
          name="age"
        />
      </FilterField>

      <FilterField>
        <FilterInput 
          value={filter.sex}
          values={sexes}
          label="Sex"
          name="sex"
        />
      </FilterField>

      <Chips/>

    </Grid>
  );
}