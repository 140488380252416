import React from 'react';

import { Grid, Typography, Button, Card, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "auto"
  },
  close: {
    float: "right",
    cursor: "pointer"
  },
  dogButton: {
    margin: theme.spacing(1),
  },
  name: {
    textAlign: "center",
    paddingBottom: theme.spacing(1),
  },
  image: {
    width: "100%"
  },
  restrictedField: {
    maxWidth: "290px"
  }
}));

export default function DogList({ dog, close }) {
  const classes = useStyles();

  const date = new Date(dog.created);
  const dateAdded = format(date, 'dd/MM/yyyy');

  return (
    <Card className={classes.root}>

      <Grid container item justify="flex-end">
        <Grid item>
          <Close className={classes.close} onClick={close}/>
        </Grid>
      </Grid>

      <Grid container item direction="column" justify="center" alignItems="center">

      <Grid item>
          <Typography component="h2" variant="h5" noWrap className={classes.name} >
            {dog.name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <img
            src={dog.images[0]} 
            title={dog.name}
            className={classes.image}
            alt={dog.name}
          />
        </Grid>

        <Grid item>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => window.open(dog.url)}
            className={classes.dogButton}
          >
            Take me to this dog!
          </Button>
        </Grid>

        <Hidden smUp>
          <Grid item>
            <Typography variant="subtitle1" noWrap className={classes.restrictedField}>
              <strong>Breed:</strong> {dog.breed}
            </Typography>
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Grid item>
            <Typography variant="subtitle1" noWrap>
              <strong>Breed:</strong> {dog.breed}
            </Typography>
          </Grid>
        </Hidden>

        <Grid item>
          <Typography variant="subtitle1" noWrap>
          <strong>Age:</strong> {dog.ageLabel}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="subtitle1" noWrap>
          <strong>Sex:</strong> {dog.sex}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="subtitle1" noWrap className={classes.restrictedField}>
          <strong>Rescue Charity:</strong> {dog.organisation.name}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="subtitle1" noWrap className={classes.restrictedField}>
          <strong>Location:</strong> {dog.location}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="subtitle1" noWrap>
          <strong>Added:</strong> {dateAdded}
          </Typography>
        </Grid>

      </Grid>
    </Card>
  );
}
