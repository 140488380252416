import React, { useState } from 'react';

import { Grid, Paper, Button, TextField } from '@material-ui/core';
import FilterMenuBar from '../Layout/FilterMenuBar';
import FilterFieldsContainer from '../Layout/FilterFieldsContainer';

import { LoadingButton } from '../Layout';
import { API } from 'aws-amplify';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  addButton: {
    marginRight: theme.spacing(2),
  },
  name: {
    width: "100%",
  },
  saveButton: {
    background: theme.palette.tertiary.main,
    marginRight: theme.spacing(2),
  },
}))

export default function Favourites({ appData }) {
  const classes = useStyles();

  const defaultFilter = {
    filterName: "New Search",
    sex: [], 
    breed: [], 
    age: [], 
  };

  const [dogsOrCats, setDogsOrCats] = useState("dogs");
  const [filters, setFilters] = useState([defaultFilter]);
  const [loading, setLoading] = useState(false);

  const handleAdd = () => {
    const updated = [...filters];
    updated.push(defaultFilter);
    setFilters(updated);
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      if (filters.length > 3) {
        console.error("Too many filters");
        throw Error("Too many filters");
      }

      const data = { body: { filters }};
      await API.post('rescuepaws', "/searches", data);

      setLoading(false);
    } catch (err) {
      console.error("error making API request", err);
      setLoading(false);
    }
  };

  const Filter = ({ filter, setFilter, }) => {
    
    const onNameChange = (e) => {
      e.preventDefault();
      const updated = { ...filter };
      updated.filterName = e.target.value;
      setFilter(updated);
    };

    return (
      <Grid item xs={12}>
        <Paper className={classes.filterContainer}>
          <TextField
            value={filter.filterName}
            onChange={onNameChange}
            className={classes.name}
            placeholder="Search Name"
          />
          <FilterFieldsContainer 
            filter={filter}
            setFilter={setFilter}
            appData={appData}
          />
        </Paper>
      </Grid>
    );
  };

  const Filters = () => {
    const setFilter = (filter, index) => {
      const updated = [...filters];
      updated[index] = filter;
      setFilters(updated);
    };

    return filters.map((filter, index) => {
      return (
        <Filter
          keyValue={index}
          filter={filter}
          setFilter={(filter) => setFilter(filter, index)}
        />
      )
    });
  };

  return (
    <Grid container spacing={3}>
      <FilterMenuBar 
        dogsOrCats={dogsOrCats} 
        setDogsOrCats={setDogsOrCats}
        hideFilter={true}
      />
      <Filters />
      <Grid container justify="flex-end">
        <Grid item>
          {filters.length < 3 && (
            <Button 
              variant="contained" 
              color="secondary"
              className={classes.addButton}
              onClick={handleAdd}
            >
              Add New
            </Button>
          )}
          <Button 
            variant="contained" 
            color="secondary"
            className={classes.saveButton}
            onClick={handleSave}
            disabled={loading}
          >
            Save
            {loading && <LoadingButton />}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
