import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { TextField, Button, Link, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Wrapper, LoadingButton } from '../Layout';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp({ setAuthState, authState, params }) {
  const classes = useStyles();
  const { push } = useHistory();

  let initialError = "";
  const sku = params.sku || "onemonth";

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(initialError);
  const [loading, setLoading] = useState("");

  const onPasswordChange = async (event) => {
    const password = event.target.value;
    setPassword(password);
  };

  const onEmailChange = async (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const onFirstNameChange = async (event) => {
    const firstName = event.target.value;
    setFirstName(firstName);
  };

  const onLastNameChange = async (event) => {
    const lastName = event.target.value;
    setLastName(lastName);
  };

  const handleError = (err) => {
    const validCode = [
      "UsernameExistsException",
      "InvalidPassword",
      "InvalidEmail",
    ].includes(err.code);
    
    console.error(err);
    if (validCode) setError(err.message);
    else throw err;
  };

  const validateEmail = (email) => {
    if (!email.includes("@")) throw {
      code: "InvalidEmail",
      message: "Invalid email"
    }
  };

  const validatePassword = (password) => {
    if (!password || password.length < 6) throw {
      code: "InvalidPassword",
      message: "Password must be longer than 8 characters"
    }
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if (!strongRegex.test(password)) throw {
      code: "InvalidPassword",
      message: "Password must have a lowercase letter, an uppercase letter and a number"
    }
  };

  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      validateEmail(email);
      validatePassword(password);
      await signUp(email, password, lastName, firstName);
      setAuthState({
        ...authState,
        email, 
        password, 
        lastName, 
        firstName, 
        status: "signedUp"
      });
      setLoading(false);
      gotoSignUpSuccess();
    } catch (err) {
      handleError(err);
      setLoading(false);
    }
  };

  const signUp = async (username, password, family_name, given_name) => {
    await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        family_name,
        given_name,
        "custom:sku": sku,
      }
    });
  };

  const gotoSignIn = _ => {
    push('/auth/signin');
  };

  const gotoSignUpSuccess = _ => {
    push('/auth/signup/success');
  };

  return (
    <Wrapper 
      title="Sign Up"
      error={error}
    > 
      {!initialError && (
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                onChange={onFirstNameChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onChange={onLastNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={onEmailChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={onPasswordChange}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSubmit}
            disabled={loading || !firstName || !lastName || !email || !password}
          >
            Sign Up
            {loading && <LoadingButton />}
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link onClick={gotoSignIn} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      )}
    </Wrapper>
  );
}