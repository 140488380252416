import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import { AppBar, Toolbar, IconButton, Badge, Grid, Hidden } from '@material-ui/core';
import { Notifications, Person, Pets, Favorite } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export default function Menu({ notifications }) {
  const classes = useStyles();
  const { push } = useHistory();
  
  const gotoDogs = _ => push('/dogs');
  const gotoSearches = _ => push('/searches');
  const gotoProfile = _ => push('/profile');

  // Change icon colour on click
  const iconColor = {
    dogs: "inherit",
    searches: "inherit",
    profile: "inherit",
  };
  const path = window.location.pathname;
  if (path.startsWith("/dogs")) iconColor.dogs = "tertiary";
  if (path.startsWith("/searches")) iconColor.searches = "tertiary";
  if (path.startsWith("/profile")) iconColor.profile = "tertiary";

  return (
    <AppBar position="absolute" className={classes.appBar} color={"primary"}>
      <Toolbar>
        <Grid container justify="space-between">
          <Grid item container spacing={2} style={{ width: "150px" }}>
            <Grid item>
              <IconButton color={iconColor.dogs} onClick={gotoDogs}>
                <Pets />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color={iconColor.searches} onClick={gotoSearches}>
                <Favorite />
              </IconButton>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item>
              <img 
                src="/logo-white.png"
                height="38px"
              />
            </Grid>
          </Hidden>
          <Grid container justify="flex-end" style={{ width: "150px" }}>
            <Grid item>
              <IconButton color="inherit">
                <Badge badgeContent={notifications.length} color="secondary">
                  <Notifications />
                </Badge>
              </IconButton>
              <IconButton color={iconColor.profile} onClick={gotoProfile}>
                <Person />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}