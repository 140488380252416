import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Pets from '../Pets';
import Favourites from '../Favourites';
import Profile from '../Profile';

export default function Routes({ user, appData }) {
  return (
    <Switch>
      <Route exact path="/dogs" 
        render={(props) => (
          <Pets {...props} user={user} appData={appData}/>
        )} 
      />
      <Route exact path="/searches" 
        render={(props) => (
          <Favourites {...props} user={user} appData={appData}/>
        )} 
      />
      <Route exact path="/profile" 
        render={(props) => (
          <Profile {...props} user={user} appData={appData}/>
        )} 
      />
      <Route path="/" 
        render={(props) => (
          <Pets {...props} user={user} appData={appData}/>
        )} 
      />
    </Switch>
  );
};