import React, { useState } from 'react';

import {  Button, Accordion, AccordionDetails, 
  Divider, AccordionActions, Hidden, 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FilterMenuBar from './FilterMenuBar';
import FilterFieldsContainer from './FilterFieldsContainer';

const useStyles = makeStyles((theme) => ({
  accordion: {
    width: "100%"
  },
}));

export default function FilterMenu({ dogsOrCats, setDogsOrCats, filter, setFilter, apply, appData }) {
  const classes = useStyles();

  const [showFilter, setShowFilter] = useState(false);

  return (
    <>
      <FilterMenuBar 
        dogsOrCats={dogsOrCats} 
        setDogsOrCats={setDogsOrCats}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        hideFilter={false}
      />

      <div className={classes.accordion}>
        <Accordion expanded={showFilter}>
          <Hidden xsUp><div>Filter</div></Hidden>
          <AccordionDetails>
            <FilterFieldsContainer 
              filter={filter}
              setFilter={setFilter}
              appData={appData}
            />
          </AccordionDetails>            
          <Divider />
          <AccordionActions>
            <Button 
              size="small" 
              onClick={() => setShowFilter(!showFilter)}
            >
              Cancel
            </Button>
            <Button 
              size="small" 
              color="primary"
              onClick={apply}
            >
              Apply
            </Button>
          </AccordionActions>
        </Accordion>
      </div>
    </>
  );
};